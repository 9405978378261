<template>
    <div class="page-wrap pt-3">
        <div class="session-form-hold">
            <div>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="white"
                />
                <v-card-text class="text-center my-0 py-0">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <span
                            style="color: white"
                        >  {{ $t('users.login.help.selectMainLang') }} </span>
                        <language-selector color="white" />

                        <v-img
                            class="mt-5"
                            src="@/assets/img/svg/arcade.svg"
                            alt=""
                            width="250px"
                            height="250px"
                            contain
                        />
                    </div>

                    <div class="mb-3">
                        <span
                            color="white"
                            style="font-size: x-large; color: white"
                            v-html="$t('users.login.title')"
                        />
                    </div>

                    <span class="red--text mb-2">
                        {{ serverError }}
                    </span>

                    <v-form
                        ref="form"
                        v-model="isValid"
                    >
                        <span />
                        <v-text-field
                            v-model="email"
                            solo
                            :label="$t('users.login.username')"
                            :rules="[rules.required]"
                            validate-on-blur
                            @keyup="serverError = ''"
                        />

                        <v-text-field
                            v-model="ePassword"
                            solo
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :label="$t('users.login.password')"
                            :rules="[rules.required, rules.validPassword]"
                            validate-on-blur
                            @click:append="showIcon = !showIcon"
                            @keyup="serverError = ''"
                        />
                        <!--                        <v-checkbox
                            v-model="checkbox1"
                            :label="$t('users.login.rememberPass')"
                        />-->
                        <!--                      :style="`opacity: ${(!isValid && !serverCheck) ? '0.2' : '1'}`"-->

                        <!--                        <div style="width: 100%; background-color: #28344a; border-radius: 3px; color: white; font-size: medium">
                            <p
                                style="padding: 8px"
                                v-html="$t('global.help.recommendedBrowser')"
                            />
                        </div>-->

                        <div style="padding: 5px; border-radius: 5px; background-color: rgba(255,255,255, 0.1);">
                            <v-btn
                                block
                                color="dark"
                                dark
                                style="z-index: 3;"
                                @click="formSubmit"
                            >
                                <v-icon left>
                                    mdi-login
                                </v-icon>
                                {{ $t('users.login.signIn') }}
                            </v-btn>

                            <div class="d-flex justify-center flex-wrap">
                                <v-btn
                                    text
                                    x-small
                                    color="white"
                                    class="mb-2 mt-5 pa-3"
                                    to="/app/sessions/forgot"
                                    outlined
                                    style="z-index: 3;"
                                >
                                    {{ $t('users.login.forgotPassword') }}
                                </v-btn>

                                <!--                                <div
                                    style="cursor: pointer; z-index: 3;"
                                    class=" mt-10 "
                                    @click="goToDownloadInstruction"
                                >
                                    <v-btn
                                        style="float: right; z-index: 3;"
                                        class="ml-1"
                                        fab
                                        dark
                                        x-small
                                        color="white"
                                        outlined
                                    >
                                        <v-icon dark>
                                            mdi-information-variant
                                        </v-icon>
                                    </v-btn>
                                    <span
                                        class="text-center white&#45;&#45;text mt-3"
                                        style="font-size: 0.7em"
                                    >
                                        {{ $t('global.help.instructionHowToDownloadAccess') }}
                                    </span>
                                </div>-->
                            </div>
                        </div>
                        <!--                        <v-btn
                            v-if="canSignUp"
                            text
                            small
                            color="white"
                            to="/app/sessions/sign-up-2"
                        >
                            {{ $t('users.login.createNewAccount') }}
                        </v-btn>-->
                    </v-form>
                </v-card-text>
            </div>
        </div>

        <!--        <v-img
            class="parpadea"
            src="@/assets/img/background_person.png"
            alt=""
            max-height="35vh"
            contain
        />-->

        <confirm-dialog
            ref="confirm"
        />
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import rules from '@/helpers/rulesValidate'
import LanguageSelector from "@/components/common/LanguageSelector";
import ConfirmDialog from '@/components/common/ConfirmDialog'

export default {
    name: 'Login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        // title: this.$t('users.login.title')
    },
    components: { LanguageSelector, ConfirmDialog },
    data() {
        return {
            isValid: true,
            serverCheck: false,
            serverError: '',
            showIcon: false,
            password: 'Password',
            checkbox1: true,
            checkbox2: false,
            email: '',
            ePassword: '',
            loading: false,
            rules: {
                ...rules
            },
            canSignUp: false
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error'])
    },
    created() {
        // this.loadConfig();
    },
    methods: {
        ...mapActions(['login']),
        /* async loadConfig() {
            const configs = await axios
                .get('configs/1');

            let options = configs?.data?.data?.attributes?.options;
            if (options) {
                options = JSON.parse(options);
                this.canSignUp = options?.canRegister ?? false;
            }
        }, */

        async formSubmit() {
            if (!this.$refs.form.validate()) return false;

            const result = await this.login({
                username: this.email.trim(),
                password: this.ePassword
            });

            if (result !== true) {
                const error = result.error;
                if (error === 'change_password') {
                    if (
                        await this.$refs.confirm.open(
                            this.$t('users.login.newPassMSG.title'),
                            this.$t('users.login.newPassMSG.msg'),
                            { noCancel: true }
                        )
                    ) {
                        const token = result.token;
                        //  "/app/sessions/pass-reset/?token="
                        this.$router.push('pass-reset/?token=' + token);
                    }
                } else {
                    this.serverError = this.$t(`errors.${error}`);
                }
            }
        },
        goToDownloadInstruction() {
            window.location.href = "https://itpaerovaluesawards.com/download/";
        }
    },
    watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },
        loggedInUser(val) {
            if (val && val.token && val.token.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                /* console.log('logged in successfully ') */
                this.loading = true
                setTimeout(() => {
                    /* console.log(val.roles)
                    console.log('val.roles') */
                    if (val.roles?.find(role => role.name === 'Admin')) {
                        this.$router.push('/app/admin')
                    } else {
                        this.$router.push('/')
                    }
                }, 500)
            }
        },
        error(val) {
            if (val != null) {
                // this.makeToast("warning", val.message);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {

  display: flex;
  flex-direction: column;
justify-content: space-between;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100%;

}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

</style>
